import makeBlockie from 'ethereum-blockies-base64';
import SHA256 from 'crypto-js/sha256';
import { FaUser } from 'react-icons/fa';

const generateEthAddress = (number) => {
  const hash = SHA256(String(number)).toString();
  // Take 40 characters from the hash and add the '0x' prefix
  return '0x' + hash.substring(0, 40);
};

const ProfilePicture = ({ userAddressId, url = null }) => {
  if (url) {
    return <img src={url} className="w-full h-full aspect-square object-cover" alt="profile" />;
  } else if (userAddressId) {
    const imgSrc = makeBlockie(generateEthAddress(userAddressId));
    return <img src={imgSrc} className="w-full h-full aspect-square object-cover" alt="profile" />;
  } else {
    return <div className='p-2 bg-cyan-800'><FaUser className="w-full h-full text-slate-200" /></div>;
  }
};

export default ProfilePicture;