import { React, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import TopicCard from "./topicCard";
import { toast } from 'react-toastify';
import { useAppContext } from '../AppContext';
import { LoginForm } from "./login";
import CreateAccount from "./createAccount";
import { CloseButton } from "./closeButton";
import { FiLoader } from "react-icons/fi";

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

const TopicAccessModal = ({ topic, onClose }) => {
    const { communityConfig } = useAppContext();
    const [topicAccessCost, setTopicAccessCost] = useState(0);
    const [buyingTopic, setBuyingTopic] = useState(false);
    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (communityConfig && topic && topic !== "loading" && communityConfig[topic.subdao_id]) {
            setTopicAccessCost(communityConfig[topic.subdao_id].topic_access_cost);
        }
    }, [communityConfig, topic]);

    useEffect(() => {
        const handlePopState = () => {
            if (isLoginModalOpen) {
                closeLoginModal();
            }
            if (isCreateAccountModalOpen) {
                closeCreateAccountModal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isLoginModalOpen, isCreateAccountModalOpen]);

    const openCreateAccountModal = () => {
        setCreateAccountModalOpen(true);
        window.history.pushState(null, '', window.location.href);
    };
    const closeCreateAccountModal = () => setCreateAccountModalOpen(false);
    const openLoginModal = () => {
        setLoginModalOpen(true);
        window.history.pushState(null, '', window.location.href);
    };
    const closeLoginModal = () => setLoginModalOpen(false);
    /* Buy a topic */
    function buyTopic() {
        setBuyingTopic(true);
        const apiUrl = apiRoot + '/topic/buy?topic_id=' + topic.id;
        const postBuyTopic = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                });

                // Check if the response status is not OK (200-299)
                if (!response.ok) {
                    setBuyingTopic(false);
                    const responseBody = await response.text();
                    throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
                }
                setBuyingTopic(false);
                closeLoginModal();
                closeCreateAccountModal();
                document.body.style.overflow = 'auto';
                onClose(); // Close the modal
                navigate('/topic/' + topic.id);
            } catch (error) {
                toast.error(`Error buying topic: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };
        postBuyTopic();
    };

    if (!topic || topic === "loading") {
        return (
            <div className="items-stretch select-none bg-white flex flex-col px-0 sm:px-8 max-w-[40rem]">
                <div className="text-cyan-950 text-center text-2xl font-bold leading-9 max-md:max-w-full">
                    Loading...
                </div>
            </div>
        );
    }

    return (
        <div className="items-stretch select-none bg-white flex flex-col px-0 sm:px-8 max-w-[40rem]">
            <div className="text-cyan-950 text-center text-2xl font-bold leading-9 max-md:max-w-full">
                View Content
            </div>
            <div className="items-stretch border border-[color:var(--Neutrals-100,#E0E0E0)] bg-white flex flex-col mt-2 p-4 rounded-lg border-solid max-md:max-w-full">
                <div className="text-slate-600 text-center text-base font-semibold leading-6 max-md:max-w-full">
                    Access to content will require
                </div>
                <div className="justify-center items-stretch self-center flex w-[77px] max-w-full gap-0 px-2 py-1 rounded-lg">
                    <div className="text-slate-600 text-right text-5xl font-bold leading-[52px] whitespace-nowrap justify-center items-stretch bg-white aspect-[0.6041666666666666] pl-1.5 py-1.5 max-md:text-4xl">
                        {topicAccessCost}
                    </div>
                    <img
                        loading="lazy"
                        src="/creds.svg"
                        alt="creds"
                        className="aspect-square object-contain object-center w-full justify-center items-center overflow-hidden shrink-0 flex-1 my-auto"
                    />
                </div>
            </div>
            <div className="items-stretch border border-[color:var(--Neutrals-100,#E0E0E0)] flex flex-col mt-6 p-5 rounded-lg border-solid max-md:max-w-full">
                <TopicCard topic={topic} />
            </div>
            {jwt ? (

                buyingTopic ? (
                    <div className="flex text-white text-center text-base font-medium leading-6 whitespace-nowrap justify-center items-center bg-cyan-800 mt-6 px-16 py-3 rounded-lg max-md:max-w-full max-md:px-5">
                        <FiLoader className="w-8 h-8 animate-spin" />
                        <span className="sr-only">Loading...</span>
                    </div>) : (
                    <button onClick={buyTopic} className="text-white text-center text-base font-medium leading-6 whitespace-nowrap justify-center items-center hover:bg-cyan-800 bg-cyan-950 mt-6 px-16 py-3 rounded-lg max-md:max-w-full max-md:px-5">
                        Confirm Access
                    </button>

                )
            ) : (
                <div className="flex flex-col w-full items-center justify-center">
                    <button onClick={openCreateAccountModal} className="text-white w-3/4 text-center text-base font-medium justify-center items-center hover:bg-cyan-800 bg-cyan-950 mt-6 py-3 rounded-lg max-md:max-w-full">
                        Create account to access
                    </button>
                    <button onClick={openLoginModal} className="text-white w-3/4 text-center text-base font-medium  justify-center items-center hover:bg-cyan-800 bg-cyan-950 mt-2 py-3 rounded-lg max-md:max-w-full">
                        Login
                    </button>
                </div>
            )}
            {
                isCreateAccountModalOpen && (
                    <div className="modal z-50 cursor-pointer" onClick={closeCreateAccountModal}>
                        <div className="modal-content cursor-default" onClick={(e) => e.stopPropagation()}>
                            <span className="close cursor-pointer" onClick={closeCreateAccountModal}><CloseButton></CloseButton></span>
                            <CreateAccount></CreateAccount>
                        </div>
                    </div>
                )
            }
            {
                isLoginModalOpen && (
                    <div className="modal z-50 cursor-pointer" onClick={closeLoginModal}>
                        <div className="modal-content select-none cursor-default" onClick={(e) => e.stopPropagation()}>
                            <span className="close cursor-pointer" onClick={closeLoginModal}><CloseButton></CloseButton></span>
                            <LoginForm></LoginForm>
                        </div>
                    </div>
                )
            }
        </div>
    );
};


export default TopicAccessModal;