import { useState, useEffect, useCallback, useRef } from 'react';
import { FaUsers, FaTimes } from 'react-icons/fa';

const SortDropdown = ({ sortCriteria, onSortChange }) => (
    <div className="flex bg-slate-200 justify-end select-none flex-grow shadow-sm">
        <select
            className="cursor-pointer outline-none text-right mr-3 text-slate-800 rounded-lg py-3 text-center bg-transparent"
            value={sortCriteria}
            onChange={(e) => onSortChange(e.target.value)}
        >
            <option value="recent">Recent</option>
            <option value="popular">Popular</option>
            <option value="trending">Trending</option>
            <option value="responses">Responses</option>
            <option value="bounty">Bounty</option>
        </select>
    </div>
);

const SearchFilterComponent = ({ onSearch, onCommunityToggle, communityConfig, selectedCommunities }) => {
    const [searchTerm, setSearchTerm] = useState(() => sessionStorage.getItem('searchTerm') || '');
    const scrollContainerRef = useRef(null);
    const scrollAnimationRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [leftFadeOpacity, setLeftFadeOpacity] = useState(0);
    const [rightFadeOpacity, setRightFadeOpacity] = useState(1);

    useEffect(() => {
        sessionStorage.setItem('searchTerm', searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer && communityConfig) {
            const checkOverflow = () => {
                const isOverflowing = scrollContainer.scrollWidth > scrollContainer.clientWidth;
                setIsOverflowing(isOverflowing);
                if (isOverflowing) {
                    setRightFadeOpacity(1);
                } else {
                    setLeftFadeOpacity(0);
                    setRightFadeOpacity(0);
                }
            };

            const handleScroll = () => {
                const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
                const scrollEnd = scrollWidth - clientWidth;
                
                setLeftFadeOpacity(Math.min(scrollLeft / 100, 1));
                setRightFadeOpacity(Math.min((scrollEnd - scrollLeft) / 100, 1));
            };

            checkOverflow();
            handleScroll();
            window.addEventListener('resize', checkOverflow);
            scrollContainer.addEventListener('scroll', handleScroll);

            if (isOverflowing) {
                const scrollWidth = scrollContainer.scrollWidth;
                const clientWidth = scrollContainer.clientWidth;
                const scrollDuration = 20000; // 20 seconds
                const scrollDistance = scrollWidth - clientWidth;
                const scrollStep = (scrollDistance / scrollDuration) * 16; // 16ms is roughly 60fps

                let scrollPosition = 0;
                const smoothScroll = () => {
                    scrollPosition += scrollStep;
                    if (scrollPosition >= scrollDistance) {
                        cancelAnimationFrame(scrollAnimationRef.current);
                    } else {
                        scrollContainer.scrollLeft = scrollPosition;
                        scrollAnimationRef.current = requestAnimationFrame(smoothScroll);
                    }
                    handleScroll();
                };

                scrollAnimationRef.current = requestAnimationFrame(smoothScroll);

                // Allow user interaction to stop the animation
                const stopAnimation = () => {
                    if (scrollAnimationRef.current) {
                        cancelAnimationFrame(scrollAnimationRef.current);
                    }
                };

                scrollContainer.addEventListener('mouseenter', stopAnimation);
                scrollContainer.addEventListener('touchstart', stopAnimation, { passive: true });

                return () => {
                    if (scrollAnimationRef.current) {
                        cancelAnimationFrame(scrollAnimationRef.current);
                    }
                    scrollContainer.removeEventListener('mouseenter', stopAnimation);
                    scrollContainer.removeEventListener('touchstart', stopAnimation);
                    window.removeEventListener('resize', checkOverflow);
                    scrollContainer.removeEventListener('scroll', handleScroll);
                };
            }
        }
    }, [communityConfig, isOverflowing]);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(searchTerm);
    };

    const clearSearch = () => {
        setSearchTerm('');
        onSearch('');
    };

    const SkeletonLoader = () => (
        <div className="animate-pulse flex space-x-4">
            <div className="rounded-full bg-slate-200 h-10 w-10"></div>
            <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                    </div>
                    <div className="h-2 bg-slate-200 rounded"></div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="w-full sm:w-auto bg-cyan-900 select-none flex-col grow shadow-sm relative flex p-6 items-start">
            <div className="w-full text-left text-white text-2xl font-bold">
                What would you like to know?
            </div>

            <form onSubmit={handleSubmit} className="w-full inner-shadow flex mt-3 relative">
                <input type="text" className="w-full outline-none px-3 py-2 rounded-l-lg pr-10"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search topics..."
                />
                {searchTerm && (
                    <button type="button" onClick={clearSearch} className="absolute right-16 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700">
                        <FaTimes />
                    </button>
                )}
                <button type="submit"
                    className="text-white hover:bg-cyan-600 transition-all py-2 px-4 bg-cyan-700 rounded-r-lg"
                >
                    <div
                        style={{ 
                            backgroundImage: 'url(/search.svg)', 
                            backgroundSize: 'cover',
                            filter: 'brightness(0) invert(1)' // This makes the SVG white
                        }}
                        className="aspect-square object-contain object-center w-5 overflow-hidden"
                        alt="search"
                    ></div>
                </button>
            </form>
            <div className="relative w-full mt-4 overflow-visible">
                {isOverflowing && (
                    <>
                        <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-cyan-900 to-transparent z-10 pointer-events-none" style={{ opacity: leftFadeOpacity }}></div>
                        <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-cyan-900 to-transparent z-10 pointer-events-none" style={{ opacity: rightFadeOpacity }}></div>
                    </>
                )}
                <div ref={scrollContainerRef} className="flex overflow-x-auto scrollbar-hide relative w-full items-center gap-2" style={{scrollBehavior: 'smooth', WebkitOverflowScrolling: 'touch'}}>
                    {communityConfig ? Object.entries(communityConfig).map(([id, { name }]) => (
                        <div key={id} className="flex items-center">
                            <button
                                className={`h-10 transition-all ease-in w-auto justify-center items-center flex-shrink-0 flex gap-1 py-2 rounded-lg group ${Object.keys(communityConfig).length > 1
                                    ? (selectedCommunities[id]
                                        ? 'px-3 bg-cyan-950 ring-2 ring-slate-100 text-slate-100'
                                        : 'px-3 sm:hover:bg-cyan-800 bg-slate-100 text-slate-800 sm:hover:text-slate-100')
                                    : 'text-slate-100 cursor-default'
                                    }`}
                                onClick={() => onCommunityToggle(id)}
                                disabled={Object.keys(communityConfig).length === 1}
                                style={{ margin: '2px' }}  // Add some margin to prevent clipping
                            >
                                <FaUsers 
                                    className={`${Object.keys(communityConfig).length > 1
                                        ? (selectedCommunities[id] ? 'fill-slate-100' : 'fill-slate-600 sm:group-hover:fill-white')
                                        : 'fill-slate-200'
                                        } w-5 h-5 transition-colors`}
                                />
                                <div className="text-center text-sm">
                                    {name}
                                </div>
                            </button>
                        </div>
                    )) : Array(10).fill(0).map((_, index) => <SkeletonLoader key={index} />)}
                </div>
            </div>
        </div>
    );
};

export { SearchFilterComponent, SortDropdown };
